<template>
  <div class="row justify-content-center d-flex align-items-center">
    <div class="image_div position-relative">
      <img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
      <div
        class="position-absolute icon_block d-flex align-items-center justify-content-center"
      >
        <img src="@/assets/image/icones/icon_block.png" />
      </div>
    </div>
    <div class="name">
      <h4>{{ user.displayName }}</h4>
      <h5>{{ getProfile(user.profile) }}</h5>
    </div>
    <ValidationObserver ref="form">
      <form class="modal_login row" @submit.prevent="loginStep">
        <div class="col-md-12 my-2" id="orderKeyCont">
          <label class="label_default mb-0">
            Digite sua
            <strong>Senha</strong>
          </label>
        </div>
        <ValidationProvider
          v-slot="{ errors, ariaMsg, classes }"
          rules="required|min:5"
          name="Senha"
          tag="div"
          :bails="false"
          class="col-md-12 my-1"
        >
          <div style="margin-bottom:10px;" id="msg" class="d-none">
            <span style="font-size: 14px;" class="alerta"
              >O Caps-Lock está ativado!</span
            >
          </div>

          <input
            :class="classes"
            type="password"
            class="px-2 py-2 input_default form-control"
            placeholder="****"
            v-model="senha"
            id="txtSenha"
          />

          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="col-md-12 my-1">
          <button
            type="submit"
            class="btn_default px-2 py-2"
            :disabled="disabled"
            id="buttonSubmitLogin"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="disabled"
            ></span>
            {{ !disabled ? "Entrar" : "" }}
          </button>
        </div>
        <div class="col-md-12 my-1">
          <p class="esqueci_senha">
            Esqueceu a senha?
            <a href="#" @click.prevent="esqueciSenha()">Clique aqui.</a>
          </p>
        </div>
        <div class="routeCont">
          <router-link :to="'/unidadeCpf'" class="outra-conta" v-if="this.sizeWindow < 768">
              {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
              <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
          </router-link>
        </div>
       
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      senha: "",
      disabled: false,
      mensagem: "",
      sizeWindow: 0
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    $("#txtSenha").keyup(function(e) {
      var key = e.charCode || e.keyCode;
      var self = this;

      //enter, caps lock e backspace não interessam
      //if (key == 13 || key == 8 || key == 46 || key == 20) {
      //  return false;
      // }

      //pega o último caracter digitado
      var tamanho = this.value.length;
      var ultimo_caracter = this.value.substring(tamanho - 1);
      var maiuscula = this.mensagem;

      if (tamanho == 0) {
        $("#msg").addClass("d-none");
        return false;
      }

      if (key == 20 && tamanho != 0) {
        $("#msg").toggleClass("d-none");
        return false;
      }

      //Verifica se é maiúsculo, e se não é shift
      if (
        ultimo_caracter.toUpperCase() == ultimo_caracter &&
        ultimo_caracter.toLowerCase() != ultimo_caracter
      ) {
        $("#msg").removeClass("d-none");
      }
    });
  },
  methods: {
    esqueciSenha() {
      this.$loading();
      this.$store.dispatch("gerateReset").then(resolve => {
        this.$loading.close();
        if (resolve) {
          // this.$emit(
          //   "loginStep",
          //   "_Esqueci_senha",
          //   "linear-gradient(to bottom, #e0a638 0%, #f38235 100%)"
          // );
          this.$router.push("/esqueci-a-senha");
        }
      });
    },
    loginStep() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store.dispatch("gerateToken", this.senha).then(resolve => {
            if (!resolve.error) {
              this.disabled = false;

              this.$parent.gratientAnimator(
                "linear-gradient(to right, #e0a638 0%,#f38235 100%)"
              );
              this.$store.dispatch("getServices");
              this.$store.dispatch("getSuporte");
              this.$store.commit("SET_TIME_START", new Date().getTime());
              if (this.$store.getters.getUser.profile != 3) {
                this.$router.push("/");
              } else {
                this.$router.push("/abrir-caixa");
              }
            } else {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${resolve.message}`
              });
              this.disabled = false;
            }
          });
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
.routeCont{
  margin-top: 17%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.routeCont a{
  color: #A5A6AD;
}
h4 {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
  font-size: 16px;
}
h5 {
  color: #f38235;
  font-size: 14px;
}
.name {
  margin-left: 16px;
  text-align: left;
}
.image_div {
  overflow: hidden;
  width: 85px;
  overflow: hidden;
  height: 85px;
  border-radius: 50%;
  border: 4px solid #2474b2;
}
.icon_block {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.49);
}

.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.esqueci_senha {
  color: #868686 !important;
}
.esqueci_senha a {
  color: #2474b2 !important;
  font-weight: 700;
}
#msg {
  margin-bottom: 1px;
}
.alerta {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: #f38235;
}
@media screen and (max-width:767px){
	.input_default{
		width: 100%;
		text-align: center;
		background-color: #ffffff !important;
		font-weight: 500 !important;
		border: 1px solid #98C4E6;
		border-radius: 7px !important;
		height: calc(2.5em + 0.75rem + 2px);
	}
	#buttonSubmitLogin{
		margin-top: 5%;
		height: 40px;
		width: 100%;
		border-radius: 10px;
		font-weight: 500;
		border: 1px solid #2474b2;
		background-color: #2474b2;
		color: #ffff;
		box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
		transition: all 0.2s linear;
		font-size: 18px;
	}
  #orderKeyCont{
    margin-top: 2.5rem!important;
  }
  label{
    font-size: 16px;
  }
  .esqueci_senha{
    font-size: 16px;
  }
  .esqueci_senha a{
    font-size: 14px;
  }
  .outra-conta{
    font-size: 16px;
  }
}
</style>
